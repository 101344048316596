import { MdAccountBalanceWallet, MdBarChart, MdHome, MdLock, MdPin } from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/access/default';
import Orders from 'views/access/orders';

// Auth Imports
import { BiHourglass } from 'react-icons/bi';
import { BsPhoneFlip, BsSafe2 } from 'react-icons/bs';
import { FaFileInvoiceDollar, FaIdCard, FaLink, FaReceipt } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { IoCashOutline } from 'react-icons/io5';
import { LuBoxes } from 'react-icons/lu';
import { PiRepeat, PiUsersFour } from 'react-icons/pi';
import Balances from 'views/access/balances/balances';
import BalancesPending from 'views/access/balances/balances-pending';
import CardsPage from 'views/access/card/CardsPage';
import Charges from 'views/access/charges';
import Charge from 'views/access/charges/Charge';
import Clients from 'views/access/clients';
import Settings from 'views/access/customers/settings';
import Insurances from 'views/access/insurances';
import Terminals from 'views/access/maquinetas';
import Movement from 'views/access/orders/Movement';
import LinksRecurrency from 'views/access/recurrences/list-links';
import Recurrency from 'views/access/recurrences/list-recurrences';
import RecurrencesPlans from 'views/access/recurrences/plans';
import ShowRecurrence from 'views/access/recurrences/ShowRecurrence';
import Terminais from 'views/access/terminais';
import Withdrawals from 'views/access/withdrawals';
import Register from 'views/auth/register';
import AddressRegister from 'views/auth/register/address';
import CompanyRegister from 'views/auth/register/company';
import CompletedOnboarding from 'views/auth/register/complete';
import CredentialsRegister from 'views/auth/register/credentials';
import DocumentsOnboarding from 'views/auth/register/documents';
import HolderRegister from 'views/auth/register/holder';
import SignIn from 'views/auth/signIn';
import Privacy from 'views/auth/signIn/privacy';
import { getVariable } from 'whitelabel';

const routes = [
  {
    name: 'Dashboard',
    layout: '/access',
    pathPrevious: '/#/access/dashboard',
    namePrevious: 'Início',
    path: '/dashboard',
    icon: MdHome,
    component: MainDashboard,
  },
  {
    name: 'Vendas',
    layout: '/access',
    icon: MdBarChart,
    path: '/transactions',
    component: Orders,
  },
  {
    name: 'Cobrança',
    layout: '/access',
    icon: MdBarChart,
    path: '/charge/:id',
    hideOnSideBar: true,
    component: Charge,
  },
  ...(getVariable('recurrencyEnabled')
    ? [
        {
          name: 'Recorrências',
          layout: '/access',
          path: '',
          icon: PiRepeat,
          children: [
            {
              name: 'Clientes',
              layout: '/access',
              icon: PiUsersFour,
              path: '/clients',
              component: Clients,
            },

            {
              name: 'Recorrências ',
              layout: '/access',
              icon: FaReceipt,
              path: '/recurrences',
              component: Recurrency,
            },
            {
              name: 'Produtos',
              layout: '/access',
              icon: LuBoxes,
              path: '/recurrence-plans',
              component: RecurrencesPlans,
            },
            {
              name: 'Consultar Recorrência',
              layout: '/access',
              hideOnSideBar: true,
              icon: FaReceipt,
              path: '/recurrence/:id',
              component: ShowRecurrence,
            },
            {
              name: 'Links de Recorrência',
              layout: '/access',
              icon: FaLink,
              path: '/recurrence-links',
              component: LinksRecurrency,
            },
          ],
        },
      ]
    : []),
  {
    name: 'Cobranças',
    layout: '/access',
    icon: FaFileInvoiceDollar,
    path: '/charges',
    component: Charges,
  },
  {
    name: 'Saldos',
    layout: '/access',
    icon: MdAccountBalanceWallet,
    children: [
      {
        name: 'Liberados',
        layout: '/access',
        icon: MdAccountBalanceWallet,
        path: '/balances',
        component: Balances,
      },

      {
        name: 'Pendentes',
        layout: '/access',
        icon: BiHourglass,
        path: '/balance-pending',
        component: BalancesPending,
      },
    ],
  },

  {
    name: 'Movimento',
    layout: '/access',
    icon: MdBarChart,
    path: '/transactions/:id',
    hideOnSideBar: true,
    component: Orders,
  },
  ...(getVariable('insuranceEnabled')
    ? [
        {
          name: 'Seguros',
          layout: '/access',
          isAdmin: true,
          icon: BsSafe2,
          path: '/insurances',
          component: Insurances,
        },
      ]
    : []),

  {
    name: 'Saques/Pagamentos',
    layout: '/access',
    icon: IoCashOutline,
    path: '/saques',
    component: Withdrawals,
  },
  {
    name: 'Venda',
    layout: '/access',
    icon: MdBarChart,
    path: '/movement/:id',
    pathPrevious: '/#/access/transactions',
    namePrevious: 'Vendas',
    component: Movement,
    hideOnSideBar: true,
  },
  ...(getVariable('terminalsEnabled')
    ? [
        {
          name: `App - ${getVariable('name')}`,
          layout: '/access',
          icon: BsPhoneFlip,
          path: '/terminais',
          component: Terminais,
        },
        {
          name: 'Terminais/Máquinas',
          layout: '/access',
          icon: MdPin,
          path: '/terminals',
          component: Terminals,
        },
      ]
    : []),
  ...(getVariable('cardsEnabled')
    ? [
        {
          name: 'Cartões',
          layout: '/access',
          icon: FaIdCard,
          path: '/cards',
          component: CardsPage,
        },
      ]
    : []),

  {
    name: 'Configurações',
    layout: '/access',
    icon: FiSettings,
    path: '/settings',
    pathPrevious: '/#/dashboard',
    namePrevious: 'Início',
    component: Settings,
  },
  {
    name: 'Entrar',
    layout: '/auth',
    hideOnSideBar: true,
    path: '/sign-in',
    icon: MdLock,
    component: SignIn,
  },
  {
    name: 'Empresa',
    layout: '/auth',
    hideOnSideBar: true,
    path: '/register/company',
    icon: MdLock,
    component: CompanyRegister,
  },
  {
    name: 'Empresa',
    layout: '/auth',
    hideOnSideBar: true,
    path: '/register/holder',
    icon: MdLock,
    component: HolderRegister,
  },
  {
    name: 'CompletedOnboarding',
    layout: '/auth',
    hideOnSideBar: true,
    path: '/register/complete',
    icon: MdLock,
    component: CompletedOnboarding,
  },
  {
    name: 'Endereço',
    layout: '/auth',
    hideOnSideBar: true,
    path: '/register/address',
    icon: MdLock,
    component: AddressRegister,
  },
  {
    name: 'Endereço',
    layout: '/auth',
    hideOnSideBar: true,
    path: '/register/documents',
    icon: MdLock,
    component: DocumentsOnboarding,
  },
  {
    name: 'Credenciais',
    layout: '/auth',
    hideOnSideBar: true,
    path: '/register/credentials',
    icon: MdLock,
    component: CredentialsRegister,
  },
  {
    name: 'Registrar',
    layout: '/auth',
    hideOnSideBar: true,
    path: '/register',
    icon: MdLock,
    component: Register,
  },
  {
    name: 'Política de Privacidade',
    layout: '/auth',
    hideOnSideBar: true,
    path: '/privacy',
    icon: MdLock,
    component: Privacy,
  },
];

export default routes;
