import { FormControl, FormErrorMessage, Switch } from "@chakra-ui/react";
import React from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import FormLabelCustom from "./FormLabel";

const SwitchForm: React.FC<{
  control: Control<any>;
  label: string;
  labelProps?: any;
  name: string;
  placeholder?: string;
  rules?: RegisterOptions<any>;
  onChange?: (value: boolean) => void;
}> = ({
  control,
  label,
  rules,
  name,
  placeholder,
  labelProps = {},
  onChange: customChange,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, error },
      }) => (
        <FormControl isInvalid={invalid}>
          <FormLabelCustom label={label} name={name} props={labelProps} />
          <Switch
            defaultValue={value}
            isChecked={value}
            placeholder={placeholder}
            onChange={(data) => {
              if (customChange) {
                customChange(data.target.checked);
              }
              onChange(data);
            }}
            value={value}
            name={name}
          />
          <FormErrorMessage>
            {error?.message || "Campo inválido"}
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default SwitchForm;
