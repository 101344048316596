import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import CustomButton from 'components/CustomButton';
import InputForm from 'components/InputForm';
import InputNumber from 'components/InputNumber';
import SelectForm from 'components/SelectForm';
import { queryClient } from 'index';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { createRecurrency } from 'services/api.service';
import { RecurrencyPeriod, RecurrencyPeriodDescription } from './recurrency-type';

export type CreateRecurrencyForm = {
  amount: string;
  description: string;
  period: RecurrencyPeriod;
};

const CreatePlan: React.FC<{}> = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, control, reset } = useForm<CreateRecurrencyForm>();
  const { mutateAsync: createRecurrencyAsync, isLoading } = useMutation({
    mutationFn: createRecurrency,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['findAllRecurrencys'] });
      onClose();
      toast({
        title: 'Sucesso',
        description: 'Recorrência adicionado com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const save = async (dataForm: CreateRecurrencyForm) => {
    createRecurrencyAsync({
      ...dataForm,
    }).catch((err) => {
      toast({
        title: 'Erro',
        description: err?.response?.data?.message || 'Erro ao criar Recorrência',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    });
  };

  useEffect(() => {
    reset({
      period: RecurrencyPeriod.ONCE,
    });
  }, [reset]);

  return (
    <>
      <CustomButton
        onClick={() => {
          onOpen();
          reset();
        }}
        variant="outline"
        colorScheme="brand"
        leftIcon={<FaPlus />}
      >
        <Text>Criar Plano</Text>
      </CustomButton>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar Produto</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3}>
                <InputForm
                  control={control}
                  name="description"
                  label="Nome do produto *"
                  placeholder="Ex: Produto 1"
                  type="text"
                  rules={{
                    required: 'Campo obrigatório',
                  }}
                />
                <InputNumber
                  control={control}
                  name="amount"
                  label="Valor *"
                  placeholder="Ex: R$ 100,00"
                  rules={{
                    required: 'Campo obrigatório',
                  }}
                />

                <SelectForm
                  control={control}
                  name="period"
                  label="Período da recorrência *"
                  options={Object.values(RecurrencyPeriod).map((period) => ({
                    label: RecurrencyPeriodDescription[period],
                    value: period,
                  }))}
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={isLoading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={isLoading}
                  onClick={handleSubmit(save)}
                >
                  Adicionar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default CreatePlan;
