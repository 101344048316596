import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { FC } from "react";
import { deleteTerminal } from "services/api.service";

const LabelItem: FC<{
  label: string;
  value: string;
}> = ({ label, value }) => {
  return (
    <VStack mb={3} width="100%" alignItems="start">
      <Text as={"small"} fontSize="sm" color="gray.600">
        {label}
      </Text>
      <Text mt={"0px !important"} fontSize="md">
        {value || "---"}
      </Text>
    </VStack>
  );
};

const ModalDeleteMaquineta: FC<{
  data: {
    id: string;
    name: string;
    number: string;
    [key: string]: any;
  };
  onClose: () => void;
}> = ({ data, onClose }) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);

  const handleDelete = async () => {
    setLoading(true);
    deleteTerminal(data.id)
      .then(() => {
        onClose();
        toast({
          title: "Sucesso",
          description: "Registro apagado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao alterar vínculo",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Modal isOpen={!!data} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Deletar Terminal</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack mb={5} width="100%" alignItems="start">
            <LabelItem label="Nome" value={data?.name} />
            <LabelItem label="Número" value={data?.number} />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button
              colorScheme="red"
              isLoading={loading}
              onClick={() => {
                onClose();
              }}
            >
              Cancelar
            </Button>
            <Button
              colorScheme="green"
              isLoading={loading}
              onClick={handleDelete}
            >
              Deletar
            </Button>
          </HStack>
        </ModalFooter>
        ;
      </ModalContent>
    </Modal>
  );
};

export default ModalDeleteMaquineta;
