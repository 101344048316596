// Chakra imports
import { Badge, Box, Flex, HStack, IconButton, SimpleGrid } from '@chakra-ui/react';

import CustomButton from 'components/CustomButton';
import TableComponent from 'components/TableComponent';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { FaEye } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { findAllRecurrences } from 'services/api.service';
import { maskCurrency } from 'utils/number';
import CreatePlan from './components/CreateRecurrence';
import EditRecurrence from './components/EditRecurrence';
import { RecurrencyPeriodDescription } from './components/recurrency-type';

export default function Planos() {
  const history = useHistory();
  const columns = useMemo(
    () =>
      [
        {
          Header: 'Nome',
          accessor: 'description',
        },
        {
          Header: 'Valor',
          accessor: 'amount',
          Cell: ({ value }) => {
            return maskCurrency(value);
          },
        },
        {
          Header: 'Período',
          accessor: 'period',
          Cell: ({ value }) => {
            return RecurrencyPeriodDescription[value];
          },
        },
        {
          Header: 'Saldo',
          accessor: 'balance',
          Cell: ({ value }) => {
            return maskCurrency(value);
          },
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({ value }: { value: string }) => {
            return value ? <Badge colorScheme="green">Ativo</Badge> : <Badge colorScheme="red">Inativo</Badge>;
          },
        },
        {
          accessor: 'createdAt',
          Header: 'Data',
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat('dd/MM/yyyy HH:mm:ss');
          },
        },
        {
          Header: 'Ações',
          accessor: 'actions',
        },
      ] as Column[],
    [],
  );

  const actions = (row: any) => (
    <Flex
      align="center"
      justify="flex-start"
      w="100%"
    >
      <EditRecurrence recurrence={row} />

      <IconButton
        aria-label="Apertar"
        rounded="md"
        size="sm"
        ml={2}
        variant="outline"
        icon={<FaEye />}
        onClick={() => history.push(`/access/recurrence/${row.id}`)}
      />
    </Flex>
  );

  return (
    <Box>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: '20px', xl: '20px' }}
      >
        <TableComponent
          label="Produtos"
          columnsData={columns}
          right={
            <HStack>
              <CustomButton
                onClick={() => history.push('/access/recurrences')}
                colorScheme="brand"
              >
                Recorrências
              </CustomButton>
              <CreatePlan />
            </HStack>
          }
          queryFn={findAllRecurrences}
          queryKey="findAllRecurrencys"
          actions={actions}
        />
      </SimpleGrid>
    </Box>
  );
}
