import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Container,
  Heading,
  Image,
  Link,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import api from 'services/api.service';
import { getVariable } from 'whitelabel';

export default function UnsubscribePage() {
  const location = useLocation();
  const [status, setStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const toast = useToast();

  const email = new URLSearchParams(location.search).get('email');
  const type = new URLSearchParams(location.search).get('type');

  const { mutate: unsubscribe, isLoading } = useMutation({
    mutationFn: async () => {
      if (!email || !type) throw new Error('Parâmetros inválidos');
      return api.post('/mail/unsubscribe', { email, type });
    },
    onSuccess: () => {
      setStatus('success');
    },
    onError: (error) => {
      setStatus('error');
      toast({
        title: 'Erro ao cancelar inscrição',
        description: 'Ocorreu um erro ao processar sua solicitação. Tente novamente mais tarde.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    document.title = getVariable('name') + ' - Cancelar Inscrição';
    let linkQuery = document.querySelector("link[rel~='icon']");
    if (!linkQuery) {
      linkQuery = document.createElement('link');
      linkQuery['rel'] = 'icon';
      document.getElementsByTagName('head')[0].appendChild(linkQuery);
    }
    linkQuery['href'] = getVariable('favicon');
  }, []);

  if (!email || !type) {
    return (
      <Container
        maxW="container.sm"
        py={10}
      >
        <Alert status="error">
          <AlertIcon />
          Link inválido. Verifique se você está usando o link correto.
        </Alert>
      </Container>
    );
  }

  return (
    <Box
      minH="100vh"
      bg="gray.50"
    >
      <Container
        maxW="container.sm"
        py={10}
      >
        <VStack
          spacing={8}
          align="stretch"
        >
          <Link
            as={RouterLink}
            to="/"
            alignSelf="center"
            _hover={{ opacity: 0.8 }}
          >
            <Image
              src={getVariable('logo')}
              width="200px"
              alt={getVariable('name')}
            />
          </Link>

          <VStack
            spacing={4}
            align="center"
          >
            <Heading
              size="lg"
              textAlign="center"
            >
              Cancelar Inscrição
            </Heading>
            <Text
              color="gray.600"
              textAlign="center"
            >
              {status === 'idle' && `Você está prestes a cancelar sua inscrição para ${email}`}
              {status === 'success' && 'Sua inscrição foi cancelada com sucesso!'}
              {status === 'error' && 'Ocorreu um erro ao cancelar sua inscrição.'}
            </Text>
          </VStack>

          {status === 'idle' && (
            <Button
              colorScheme="red"
              size="lg"
              onClick={() => unsubscribe()}
              isLoading={isLoading}
              loadingText="Cancelando..."
            >
              Confirmar Cancelamento
            </Button>
          )}

          {status === 'success' && (
            <Alert status="success">
              <AlertIcon />
              Você foi removido com sucesso da nossa lista de emails.
            </Alert>
          )}
        </VStack>
      </Container>
    </Box>
  );
}
