import { InfoIcon } from '@chakra-ui/icons';
import { Box, Container, Icon, SimpleGrid, Skeleton, Text, VStack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { Redirect, useParams } from 'react-router-dom';
import { findSharedRecurrencyLink } from 'services/api.service';

// Custom Chakra theme
export default function SharedLinkPage() {
  const { id } = useParams<{ id: string }>();

  const { data: link, isLoading } = useQuery({
    queryKey: ['findSharedRecurrencyLink', id],
    queryFn: () => findSharedRecurrencyLink(id),
    enabled: !!id,
  });

  if (isLoading) {
    return (
      <Container
        maxW="container.lg"
        py={10}
      >
        <VStack spacing={8}>
          <Skeleton
            height="200px"
            width="100%"
          />
          <Skeleton
            height="40px"
            width="60%"
          />
          <Skeleton
            height="20px"
            width="80%"
          />
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3 }}
            spacing={6}
            width="100%"
          >
            {[1, 2, 3].map((i) => (
              <Skeleton
                key={i}
                height="300px"
              />
            ))}
          </SimpleGrid>
        </VStack>
      </Container>
    );
  }

  return link?.url ? (
    <Redirect to={`${link.url}?refferer=${id}`} />
  ) : (
    <NoData message="Falha ao carregar link compartilhado" />
  );
}

interface NoDataProps {
  message?: string;
}

const NoData: React.FC<NoDataProps> = ({ message = 'Nenhum dado disponível' }) => {
  return (
    <Box
      p={4}
      borderRadius="md"
      borderWidth="1px"
      borderStyle="dashed"
      borderColor="gray.300"
      bg="gray.50"
      width="100%"
    >
      <VStack
        spacing={2}
        align="center"
      >
        <Icon
          as={InfoIcon}
          boxSize={6}
          color="gray.500"
        />
        <Text
          color="gray.600"
          fontWeight="medium"
          textAlign="center"
        >
          {message}
        </Text>
      </VStack>
    </Box>
  );
};
