// Chakra imports
import { Badge, Box, Flex, IconButton, SimpleGrid, Tooltip } from '@chakra-ui/react';
import CustomButton from 'components/CustomButton';

import TableComponent from 'components/TableComponent';
import { queryClient } from 'index';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { FaEye } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { Column } from 'react-table';
import { findAllMovements } from 'services/api.service';
import { MovementsTypeDescription } from 'types/Movement';

export enum MovementsStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
}

export const StatusRender = (status: MovementsStatus) => {
  if (status === MovementsStatus.PENDING) return <Badge colorScheme="orange">Pendente</Badge>;
  if (status === MovementsStatus.APPROVED) return <Badge colorScheme="green">Aprovado</Badge>;
  if (status === MovementsStatus.REFUNDED) return <Badge colorScheme="orange">Devolvido</Badge>;
  if (status === MovementsStatus.REJECTED) return <Badge colorScheme="red">Rejeitado</Badge>;
  if (status === MovementsStatus.CANCELED) return <Badge colorScheme="red">Cancelado</Badge>;
};

export default function Orders() {
  const history = useHistory();
  const columns = useMemo(
    () =>
      [
        {
          Header: 'Tipo',
          accessor: 'type',
          Cell: ({ value }) => {
            return MovementsTypeDescription[value];
          },
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({ value }: { value: MovementsStatus }) => {
            return StatusRender(value);
          },
        },
        {
          Header: 'Valor',
          accessor: 'amount',
          align: 'right',
          Cell: ({ value }) => {
            return (+value).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            });
          },
        },
        {
          Header: 'Taxas',
          align: 'right',
          accessor: 'taxes',
          Cell: ({ value }) => {
            return (+value).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            });
          },
        },
        {
          Header: 'Valor Líquido',
          align: 'right',
          accessor: 'amountLiquid',
          Cell: ({ value }) => {
            return (+value).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            });
          },
        },

        {
          accessor: 'Maquinetas.number',
          Header: 'Terminal',
          Cell: ({ value, row }: any) => {
            return <Tooltip label={row.original?.Maquinetas?.name}>{value || 'API'}</Tooltip>;
          },
        },

        {
          accessor: 'orderAt',
          Header: 'Data',
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat('dd/MM/yyyy HH:mm:ss');
          },
        },

        {
          Header: 'Ações',
          accessor: 'actions',
        },
      ] as Column[],
    [],
  );

  const actions = (row: any) => (
    <>
      <Flex
        align="center"
        justify="flex-start"
        w="100%"
        display={{ base: 'none', md: 'flex' }}
      >
        <IconButton
          aria-label="Apertar"
          rounded="md"
          size="sm"
          variant="outline"
          icon={<FaEye />}
          onClick={() => history.push(`/access/movement/${row.id}`)}
        />
      </Flex>
      <Flex
        align="center"
        justify="flex-start"
        w="100%"
        display={{ base: 'flex', md: 'none' }}
      >
        <CustomButton
          variant="solid"
          colorScheme="brand"
          onClick={() => history.push(`/access/movement/${row.id}`)}
        >
          Acessar
        </CustomButton>
      </Flex>
    </>
  );

  const handleRefresh = async () => {
    await queryClient.invalidateQueries({ queryKey: ['findAllMovements'] });
  };

  // Chakra Color Mode
  return (
    <PullToRefresh onRefresh={handleRefresh}>
      <Box>
        <SimpleGrid
          mb="20px"
          columns={{ sm: 1 }}
          spacing={{ base: '20px', xl: '20px' }}
        >
          <TableComponent
            columnsData={columns}
            filterable={['customerId', 'maquinetaId', 'createdAt', 'promoterId']}
            queryFn={findAllMovements}
            exportPdf={true}
            exportCsv={true}
            queryKey="findAllMovements"
            actions={actions}
            label="Movimentações de vendas"
          />
        </SimpleGrid>
      </Box>
    </PullToRefresh>
  );
}
