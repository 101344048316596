import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import InputForm from 'components/InputForm';
import InputMaskForm from 'components/InputMask';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { createTerminal } from 'services/api.service';
import { validCpf } from 'services/validDocument';
import { onlyNumbers } from 'utils/number';

const ModalAddTerminal: FC<{
  onClose: () => void;
  isOpen: boolean;
}> = ({ onClose, isOpen }) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit } = useForm<{
    name: string;
    email: string;
    document: string;
  }>();

  const save = async (dataForm: { name: string; email: string; document: string }) => {
    setLoading(true);
    createTerminal({
      name: dataForm.name,
      document: onlyNumbers(dataForm.document),
      email: dataForm.email,
    })
      .then(() => {
        onClose();
        toast({
          title: 'Sucesso',
          description: 'Terminal adicionada com sucesso',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: 'Erro',
          description: err?.response?.data?.message || 'Erro ao alterar vínculo',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Adicionar Terminal</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={5}>
            <Text>
              O terminal é um equipamento que será vinculado a um cliente.
              <br />a autenticação será feita através do email e do CPF do cliente.
            </Text>

            <InputForm
              control={control}
              name="name"
              label="Nome"
              type="text"
              placeholder="Nome"
              rules={{ required: 'Campo obrigatório' }}
            />
            <InputForm
              control={control}
              name="email"
              label="E-mail"
              type="email"
              placeholder="E-mail"
              rules={{ required: 'Campo obrigatório' }}
            />
            <InputMaskForm
              mask="999.999.999-99"
              control={control}
              label="CPF"
              name="document"
              size="sm"
              isRequired={true}
              type="tel"
              placeholder="CPF"
              rules={{
                validate: (value) => {
                  if (!value) return false;

                  return validCpf(onlyNumbers(value)) || 'CPF inválido';
                },
              }}
              required
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button
              colorScheme="red"
              isLoading={loading}
              onClick={() => {
                onClose();
              }}
            >
              Cancelar
            </Button>
            <Button
              colorScheme="green"
              isLoading={loading}
              onClick={handleSubmit(save)}
            >
              Adicionar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalAddTerminal;
