import '@capacitor-community/safe-area';
import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import MasterContext from 'context';
import Charge from 'layouts/charge';
import LinkPage from 'layouts/link';
import SharedLinkPage from 'layouts/shared';
import UnsubscribePage from 'layouts/unsubscribe';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { getVariable } from 'whitelabel';
import './assets/css/App.css';
import AdminLayout from './layouts/access';
import AuthLayout from './layouts/auth';
import theme from './theme/theme';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

export const queryClient = new QueryClient();

Sentry.init({
  dsn: 'https://4dc1fe5f22a442d879f8243a0353e393@o1407737.ingest.us.sentry.io/4508806588792832',
  environment: getVariable('name'),
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/acesso.divipay.com.br\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ChakraProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <HashRouter>
          <Switch>
            <Route
              path={`/charge/:id`}
              component={Charge}
            />
            <Route
              path={`/link/:id`}
              component={LinkPage}
            />
            <Route
              path={`/unsubscribe`}
              component={UnsubscribePage}
            />
            <Route
              path={`/shared/:id`}
              component={SharedLinkPage}
            />
            <MasterContext>
              <>
                <Route
                  path={`/auth`}
                  component={AuthLayout}
                />
                <Route
                  path={`/access`}
                  component={AdminLayout}
                />
                <Redirect
                  from="/"
                  to="/access"
                />
              </>
            </MasterContext>
          </Switch>
        </HashRouter>
      </React.StrictMode>
    </QueryClientProvider>
  </ChakraProvider>,
);
