import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
// Custom components
import { Capacitor } from '@capacitor/core';
import Card from 'components/card/Card';
import FileSaver from 'file-saver';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaUpload } from 'react-icons/fa';
import { PiFunnel } from 'react-icons/pi';
import { useHistory, useLocation } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { findAllTerminals, findTerminalsApp } from 'services/api.service';
import { BalanceTypeDescription } from 'types/Balances';
import { maskCurrency } from 'utils/number';
import { shareFileFromBuffer } from 'utils/saver';
import CustomButton from './CustomButton';
import FormRemoteSelectInput from './FormRemoteSelectInput';
import InputForm from './InputForm';
import MobileList from './MobileList';
import SelectForm from './SelectForm';
import TableList from './Table';
import MiniStatistics from './card/MiniStatistics';

const paramsToQuery = [
  'pageIndex',
  'pageSize',
  'orderBy',
  'filter',
  'customerId',
  'maquinetaId',
  'companyId',
  'initialDate',
  'finalDate',
  'charges',
  'promoterId',
];

function objectToBase64(obj: FilterTable) {
  // Convert object to JSON string
  const jsonString = JSON.stringify(obj);

  // Encode JSON string to Base64
  const base64String = btoa(jsonString);

  return base64String;
}

function base64ToObject(base64String) {
  // Decode Base64 string to JSON string
  const jsonString = atob(base64String);

  // Parse JSON string back to JavaScript object
  return JSON.parse(jsonString);
}

interface Aggregate {
  total: number;
  key: string;
}

interface FilterTable {
  pageIndex: number;
  pageSize: number;
  orderBy: any[];
  filter: string;
  customerId?: string;
  maquinetaId?: string;
  companyId?: string;
  initialDate?: string;
  finalDate?: string;
  charges?: string;
  promoterId?: string;
  query?: Record<string, any>;
}

export default function TableComponent(props: {
  queryFn: Function;
  columnsData: any[];
  exportPdf?: boolean;
  exportCsv?: boolean;
  queryKey: string;
  filterable?: string[];
  actions: any;
  right?: any;
  setSelectedRows?: any;
  enableSelect?: boolean;
  label: string;
  aggregateSelection?: {
    key: string;
    label: string;
  }[];
}) {
  const toast = useToast();
  const [isLoaded, setIsLoaded] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const {
    columnsData,
    label,
    queryFn,
    queryKey,
    actions,
    right,
    filterable = [],
    setSelectedRows,
    enableSelect,
    exportPdf,
    exportCsv,
    aggregateSelection,
  } = props;
  const loc = useLocation<FilterTable>();
  const history = useHistory();
  const params = new URLSearchParams(loc.search);
  const entries = params.get('queryfilter') ? base64ToObject(params.get('queryfilter')) : {};

  const [filter, setFilter] = React.useState<FilterTable>({
    pageIndex: 0,
    pageSize: 20,
    orderBy: [],
    ...(filterable?.includes('createdAt') && {
      initialDate: DateTime.now().startOf('month').toISO(),
      finalDate: DateTime.now().endOf('month').toISO(),
    }),
    filter: '',
  });
  const [busca, setBusca] = React.useState('');
  const { control, setValue } = useForm();

  useEffect(() => {
    if (!isLoaded) {
      if (entries.query) {
        Object.keys(entries.query).map((key) => {
          setValue(key, entries.query[key]);
          return key;
        });
      }
      if (entries.filter) {
        setBusca(entries.filter);
      }

      const fieldToAdd = {};

      for (const filterableItem of filterable ?? []) {
        if (params.get(filterableItem)) {
          fieldToAdd[filterableItem] = params.get(filterableItem);
        }
      }

      setFilter((prev) => ({ ...prev, ...fieldToAdd, ...entries }));
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entries]);

  useDebounce(
    () => {
      setFilter((prev) => ({ ...prev, filter: busca }));
    },
    500,
    [busca],
  );

  const { data, isLoading } = useQuery<{
    pages: number;
    registers: any[];
    aggregate: Aggregate[];
  }>(
    [queryKey, filter],
    () =>
      queryFn({
        page: filter.pageIndex,
        filter: filter.filter,
        customerId: filter.customerId,
        companyId: filter.companyId,
        maquinetaId: filter.maquinetaId,
        promoterId: filter.promoterId,
        limit: filter.pageSize,
        orderBy: filter.orderBy?.[0]?.id,
        direction: filter.orderBy?.[0]?.desc ? 'desc' : 'asc',
        initialDate: filter.initialDate,
        finalDate: filter.finalDate,
        query: filter.query,
        charges: filter.charges,
      }),
    {
      keepPreviousData: true,
    },
  );

  const exportToFile = (type: 'pdf' | 'csv') => {
    if (!filter.initialDate || !filter.finalDate) {
      toast({
        title: 'Erro',
        description: 'Selecione uma data inicial e final',
        status: 'error',
        duration: 9000,
        position: 'top',
        isClosable: true,
      });
      return;
    }
    setLoading(true);
    queryFn(
      {
        page: filter.pageIndex,
        filter: filter.filter,
        customerId: filter.customerId,
        companyId: filter.companyId,
        promoterId: filter.promoterId,
        maquinetaId: filter.maquinetaId,
        limit: filter.pageSize,
        orderBy: filter.orderBy?.[0]?.id,
        direction: filter.orderBy?.[0]?.desc ? 'desc' : 'asc',
        initialDate: filter.initialDate,
        finalDate: filter.finalDate,
        query: filter.query,
        charges: filter.charges,
        exportCsv: type === 'csv',
      },
      true,
    )
      .then(async (res: any) => {
        setLoading(false);
        if (Capacitor.isNativePlatform()) {
          try {
            await shareFileFromBuffer(
              type === 'pdf' ? 'relatorio.pdf' : 'relatorio.csv',
              type === 'pdf' ? 'application/pdf' : 'text/csv',
              res,
            );
          } catch (error) {
            console.error('Error sharing image:', error);
          }
        } else {
          if (type === 'pdf') {
            FileSaver(res, 'relatorio.pdf');
          } else {
            FileSaver(res, 'relatorio.csv', {
              autoBom: false,
            });
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: 'Erro',
          description: 'Ocorreu um erro ao gerar o relatório',
          status: 'error',
          duration: 9000,
          position: 'top',
          isClosable: true,
        });
      });
  };

  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

  useEffect(() => {
    const existsDiff = Object.keys(filter).some((key) => {
      if (!paramsToQuery.includes(key)) {
        return false;
      }
      return String(filter[key]) !== String(params.get(key));
    });
    if (existsDiff) {
      const fieldToAdd = {};

      for (const filterableItem of filterable ?? []) {
        if (params.get(filterableItem)) {
          fieldToAdd[filterableItem] = params.get(filterableItem);
        }
      }
      history.replace({
        search: new URLSearchParams({
          ...fieldToAdd,
          queryfilter: objectToBase64({ ...filter, ...fieldToAdd }),
        }).toString(),
        state: { ...filter, ...fieldToAdd },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);
  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        px={0}
        pt={5}
        pb={3}
      >
        <HStack
          px={0}
          justifyContent="space-between"
          flexDirection={{
            base: 'column',
            md: 'row',
          }}
        >
          <Text
            px={3}
            fontWeight={700}
            fontSize="xl"
          >
            {label}
          </Text>
          <HStack
            width={{ base: '100%', md: 'auto' }}
            px={3}
            justifyContent="flex-end"
            flexDirection={{
              base: 'column',
              md: 'row',
            }}
          >
            <CustomButton
              leftIcon={<PiFunnel />}
              onClick={onOpen}
            >
              Filtros
            </CustomButton>

            <Drawer
              isOpen={isOpen}
              placement="right"
              onClose={onClose}
            >
              <DrawerOverlay />
              <DrawerContent
                pb="var(--safe-area-inset-bottom)"
                pt="var(--safe-area-inset-top)"
              >
                <DrawerCloseButton mt="var(--safe-area-inset-top)" />
                <DrawerHeader>Realizar filtros</DrawerHeader>

                <DrawerBody>
                  <VStack>
                    <InputForm
                      placeholder="Filtrar por palavras chaves..."
                      control={control}
                      name="filter"
                      value={busca}
                      onChange={(value) => setBusca(value)}
                      label="Filtrar"
                      type="text"
                      size="sm"
                    />

                    {columnsData
                      .filter((item) => item.filter)
                      .map((itemColumn) => {
                        return (
                          <>
                            <SelectForm
                              placeholder={`Filtrar por ${itemColumn.Header}...`}
                              control={control}
                              name={itemColumn.accessor}
                              label={itemColumn.Header}
                              onChange={(value) =>
                                setFilter((prev) => ({
                                  ...prev,
                                  query: {
                                    ...prev.query,
                                    [itemColumn.accessor]: value,
                                  },
                                }))
                              }
                              options={itemColumn.values.map((key) => ({
                                label: key.label,
                                value: key.value,
                              }))}
                            />
                          </>
                        );
                      })}

                    {filterable?.includes('createdAt') && (
                      <>
                        <InputForm
                          control={control}
                          name="initialDate"
                          label="Data Inicial"
                          type="datetime-local"
                          value={filter.initialDate?.substring(0, 16)}
                          size="sm"
                          onChange={(value) =>
                            setFilter((prev) => ({
                              ...prev,
                              initialDate: DateTime.fromISO(value).toISO(),
                            }))
                          }
                        />
                        <InputForm
                          control={control}
                          name="finalDate"
                          label="Data Final"
                          value={filter.finalDate.substring(0, 16)}
                          type="datetime-local"
                          size="sm"
                          onChange={(value) =>
                            setFilter((prev) => ({
                              ...prev,
                              finalDate: DateTime.fromISO(value).toISO(),
                            }))
                          }
                        />
                      </>
                    )}

                    {filterable?.includes('promoterId') && (
                      <FormRemoteSelectInput
                        control={control}
                        name="promoter"
                        label="Promoter"
                        size="sm"
                        onChange={(e) => {
                          setFilter((prev) => ({
                            ...prev,
                            promoterId: e.value,
                          }));
                        }}
                        loadDataFn={({ value, cb }) =>
                          findTerminalsApp({
                            filter: value,
                            limit: 10,
                            page: 0,
                            orderBy: [
                              {
                                id: 'name',
                                desc: false,
                              },
                            ],
                          }).then((retorno) => {
                            if (retorno?.registers?.length > 0) {
                              cb([
                                { label: 'Escolher uma opção...', value: '' },
                                ...retorno.registers?.map((d: any) => ({
                                  label: d.name,
                                  value: d.id,
                                })),
                              ]);
                            } else {
                              cb([]);
                            }
                          })
                        }
                      />
                    )}

                    {filterable?.includes('onlyCharges') && (
                      <SelectForm
                        control={control}
                        name="charges"
                        label="Cobranças"
                        options={[
                          {
                            label: 'Selecione...',
                            value: '',
                          },
                          {
                            label: 'Apenas Cobranças',
                            value: 'onlyCharges',
                          },
                          {
                            label: 'Sem Cobranças',
                            value: 'noCharges',
                          },
                        ]}
                        onChange={(e) => {
                          setFilter((prev) => ({ ...prev, charges: e }));
                        }}
                      />
                    )}

                    {filterable?.includes('maquinetaId') && (
                      <FormRemoteSelectInput
                        control={control}
                        name="maquineta"
                        label="Terminal"
                        size="sm"
                        onChange={(e) => {
                          setFilter((prev) => ({
                            ...prev,
                            maquinetaId: e.value,
                          }));
                        }}
                        loadDataFn={({ value, cb }) =>
                          findAllTerminals({
                            filter: value,
                            limit: 10,
                            page: 0,
                            orderBy: [
                              {
                                id: 'name',
                                desc: false,
                              },
                            ],
                          }).then((retorno) => {
                            if (retorno?.registers?.length > 0) {
                              cb([
                                { label: 'Escolher uma opção...', value: '' },
                                ...retorno.registers?.map((d: any) => ({
                                  label: `${d.number} - ${d.name}`,
                                  value: d.id,
                                })),
                              ]);
                            } else {
                              cb([]);
                            }
                          })
                        }
                      />
                    )}
                    {exportPdf && (
                      <Button
                        leftIcon={<FaUpload />}
                        size="sm"
                        rounded="md"
                        width={'100%'}
                        variant="outline"
                        colorScheme="brand"
                        isLoading={loading}
                        onClick={() => exportToFile('pdf')}
                      >
                        Exportar PDF
                      </Button>
                    )}
                    {exportCsv && (
                      <Button
                        leftIcon={<FaUpload />}
                        size="sm"
                        borderRadius="sm"
                        isLoading={loading}
                        variant="outline"
                        width={'100%'}
                        colorScheme="brand"
                        rounded={'sm'}
                        onClick={() => exportToFile('csv')}
                      >
                        Exportar CSV
                      </Button>
                    )}
                  </VStack>
                </DrawerBody>

                <DrawerFooter>
                  <VStack width={'100%'}>
                    <CustomButton onClick={onClose}>Fechar</CustomButton>
                    <Button
                      colorScheme="brand"
                      size={'sm'}
                      width={'100%'}
                      leftIcon={<PiFunnel />}
                      rounded={'md'}
                      onClick={() => {
                        setFilter({
                          pageIndex: 0,
                          pageSize: 20,
                          orderBy: [],
                          filter: '',
                          ...(filterable?.includes('createdAt') && {
                            initialDate: DateTime.now().startOf('month').toISO(),
                            finalDate: DateTime.now().endOf('month').toISO(),
                          }),
                        });
                        onClose();
                      }}
                    >
                      Limpar Filtros
                    </Button>
                  </VStack>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
            {right}
          </HStack>
        </HStack>

        {data?.aggregate?.length > 0 && (
          <Stack
            direction={{ base: 'column', md: 'row' }}
            px={3}
            py={5}
          >
            {data?.aggregate?.map((a) => {
              return (
                <MiniStatistics
                  name={BalanceTypeDescription[a.key]}
                  value={maskCurrency(Math.abs(+a.total))}
                />
              );
            })}
          </Stack>
        )}
        <Divider mt={2} />
        {isLoading ? (
          <Flex
            justify="center"
            align="center"
            h="100%"
            w="100%"
            minH={{ sm: '100px', lg: '200px' }}
            color="gray.500"
          >
            <Text>Carregando...</Text>
          </Flex>
        ) : isLargerThan800 ? (
          <TableList
            actions={actions}
            columnsData={columnsData}
            itensData={data?.registers || []}
            pageCount={data?.pages || 1}
            setFilter={setFilter}
            enableSelect={enableSelect}
            setSelectedRows={setSelectedRows}
            aggregateSelection={aggregateSelection}
          />
        ) : (
          <MobileList
            actions={actions}
            columnsData={columnsData}
            itensData={data?.registers || []}
            pageCount={data?.pages || 1}
            setFilter={setFilter}
            enableSelect={enableSelect}
            setSelectedRows={setSelectedRows}
            aggregateSelection={aggregateSelection}
          />
        )}
      </Card>
    </>
  );
}
