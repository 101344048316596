export enum RecurrencyPeriod {
  ONCE = 'ONCE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
  BIMONTHLY = 'BIMONTHLY',
  TRIMESTER = 'TRIMESTER',
  QUARTERLY = 'QUARTERLY',
  SEMIANNUALLY = 'SEMIANNUALLY',
  ANNUALLY = 'ANNUALLY',
}

export enum RecurrencyPeriodDescription {
  ONCE = 'Única',
  DAILY = 'Diário',
  WEEKLY = 'Semanal',
  BIWEEKLY = 'Bi-Semanal',
  MONTHLY = 'Mensal',
  BIMONTHLY = 'Bi-Mensal',
  TRIMESTER = 'Trimestral',
  QUARTERLY = 'Quadrimestral',
  SEMIANNUALLY = 'Semestral',
  ANNUALLY = 'Anual',
}
