// Chakra imports
import { Box, Button, ButtonGroup, IconButton, SimpleGrid, Text, Tooltip, useDisclosure } from '@chakra-ui/react';

import { AddIcon } from '@chakra-ui/icons';
import TableComponent from 'components/TableComponent';
import { queryClient } from 'index';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { Column } from 'react-table';
import { findTerminalsApp } from 'services/api.service';
import { maskDocument } from 'utils/formatters';
import EditTerminal from './components/EditTerminal';
import ModalAddTerminal from './components/ModalAddMaquineta';
import ModalDeleteMaquineta from './components/ModalDeleteMaquineta';

export default function Terminais() {
  const [removeMaquineta, setRemoveMaquineta] = useState<any>();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Nome',
          accessor: 'name',
        },
        {
          Header: 'E-mail',
          accessor: 'email',
        },
        {
          Header: 'Documento',
          accessor: 'document',
          Cell: ({ value }) => {
            return value ? maskDocument(value) : '---';
          },
        },

        {
          accessor: 'createdAt',
          Header: 'Data de criação',
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat('dd/MM/yyyy HH:mm:ss');
          },
        },

        {
          accessor: 'lastAccess',
          Header: 'Último acesso',
          Cell: ({ value }) => {
            return value ? DateTime.fromISO(value).toFormat('dd/MM/yyyy HH:mm:ss') : '---';
          },
        },

        {
          Header: 'Ações',
          accessor: 'actions',
        },
      ] as Column[],
    [],
  );

  const actions = (row: any) => (
    <ButtonGroup>
      {
        <>
          <EditTerminal
            terminal={row}
            refetch={refetch}
          />
          <Tooltip
            label="Deletar terminal"
            aria-label="Visualizar"
          >
            <IconButton
              aria-label="Alterar"
              rounded="md"
              size="sm"
              variant="outline"
              icon={<FaTrashAlt />}
              onClick={() => setRemoveMaquineta(row)}
            />
          </Tooltip>
        </>
      }
    </ButtonGroup>
  );

  const refetch = () => {
    queryClient.refetchQueries({
      queryKey: ['findAllTerminals'],
      type: 'active',
    });
  };

  // Chakra Color Mode
  return (
    <Box pt={{ base: '80px', md: '80px', xl: '80px' }}>
      {isOpen && (
        <ModalAddTerminal
          onClose={() => {
            onClose();
            refetch();
          }}
          isOpen={isOpen}
        />
      )}
      {removeMaquineta && (
        <ModalDeleteMaquineta
          data={removeMaquineta}
          onClose={() => {
            setRemoveMaquineta(null);
            refetch();
          }}
        />
      )}
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: '20px', xl: '20px' }}
      >
        <TableComponent
          label="Terminais"
          columnsData={columns}
          queryFn={findTerminalsApp}
          queryKey="findAllTerminals"
          filterable={['customerId']}
          actions={actions}
          right={
            <ButtonGroup>
              {
                <Button
                  onClick={onOpen}
                  size="sm"
                  rounded="sm"
                  variant="outline"
                  colorScheme="brand"
                >
                  <AddIcon></AddIcon>
                  <Text ml={2}>Adicionar</Text>
                </Button>
              }
            </ButtonGroup>
          }
        />
      </SimpleGrid>
    </Box>
  );
}
